import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PublicRoutingModule } from './public-routing.module';
import { RouterModule } from '@angular/router';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TokenResolverService } from '../core/services/token-resolver.service';
import { CookieModule } from 'ngx-cookie';

@NgModule({
  declarations: [],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    PublicRoutingModule,
    SlickCarouselModule,
    InfiniteScrollModule,
    BrowserAnimationsModule,
    CookieModule.forRoot()
  ],
  providers: [
    TokenResolverService
  ]
})
export class PublicModule { }
