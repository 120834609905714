<div class="search-bg-img">
    <div class="search-page" [ngClass]="{'h-100': (searchCount==0 && this.totalGetCount == 0)}">
        <!-- <a class="pull-right close" (click)="closeSearch()">
        </a> -->
        <!-- <div class="search-box">
            <span class="glyphicon glyphicon-search pull-left search-logo"></span>
            <input id="searchTerm" [(ngModel)]="searchKey" placeholder="{{ configService.localeKeys?.srch_inptbxl_typng_def}}" type="search" (ngModelChange)="getSearchData(searchKey)" (keyup.enter)="goToSearchPage(searchKey)" spellcheck="false" autocomplete="off"
                [ngModelOptions]="{debounce: 1000}">
            <a class="pull-right close" (click)="closeSearch()">
            </a>
        </div> -->
        <div class="search-count" [ngClass]="(searchCount==0 && this.totalGetCount == 0) ? 'height-100':'load-height'">
            <div *ngIf="!overrideLoader" [ngClass]="(searchCount==0 && this.totalGetCount == 0) ? 'no-result-content' : 'result-block triangle'">
                <div *ngIf="totalGetCount">
                    <!-- {{ configService.localeKeys?.search_results_indicator_part_1 }} {{searchCount}} {{ configService.localeKeys?.search_results_indicator_part_2}} <span>&#8220;</span>{{searchName}}<span>&#8221; {{ configService.localeKeys?.search_results_indicator_part_3}} </span>. -->
                    {{ configService.localeKeys?.search_results_indicator_part_2}} <span>&#8220;</span>{{searchName}}<span>&#8221; {{ configService.localeKeys?.search_results_indicator_part_3}} </span>.

                </div>
                <div *ngIf="(searchCount==0 && this.totalGetCount == 0)">
                    <div id="no-result">{{configService.localeKeys?.no_results_found}} <span>&#8220;</span>
                        {{searchName}}<span>&#8221;</span>.</div>
                    <!-- <div class="no-result-else">{{ configService.localeKeys?.maybe_something_else_definition }}</div> -->
                </div>
            </div>
            <div *ngIf="overrideLoader" id="align-lottie">
                <ng-lottie width="100px" height="100px" [options]="options"></ng-lottie>
            </div>
        </div>
        <div *ngIf="totalGetCount" class="searchList">
            <app-card-list id="card-align" [searchTerm]="searchName" [isSearch]="true" [numCardsInRow]="numCardsInRow" [cards]="searchData" [searchComp]="true">
            </app-card-list>
            <div class="loader" *ngIf="paginate">
                <img src="assets/{{appName}}/img/card-loader.svg" alt="">
            </div>
            <div class="card-clearfix"></div>
            <div class="open-more">
                <div id="open-trans" *ngIf="showMoreResult && !showLessButton">
                    <span> {{configService.localeKeys?.kws_kw_more_def}} <img (click)="openMore()" class="cursor-pointer" src="assets/{{appName}}/img/show-more.svg" alt=""></span>
                </div>
                <!-- <div *ngIf="showLessButton" style="transition: all 0.9s ease-in-out;">
                    <span> {{configService.localeKeys?.kws_kw_more_def}} <img (click)="showLess()" style="transform: rotate(180deg);cursor: pointer;" src="assets/film1/img/show-more.svg" alt=""></span>
                </div> -->
            </div>
        </div>
        <!-- <div *ngIf="searchCount" class="something-else">
            {{ configService.localeKeys?.maybe_something_else_definition }}
        </div> -->
        <!-- <div *ngFor="let section of data" class="search-data">
            <div *ngIf="section.list.length>0">
                <div class="box-title">
                    <div class="box-head row no-margin">
                        <h3 class="main-title pull-left">{{section.title}}</h3>
                    </div>
                </div>
                <div class="section-line"></div>
                <app-slick-item [data]="section" (closeEvent)="closeSearch()" [searchData]="searchKey"></app-slick-item>
            </div>
        </div> -->
        <!-- <div class="row no-margin" style="margin-top:40px !important;color:var(--mm-font-color)" *ngIf="search_count==0 && searchKey.length >=3">
            {{no_results_message }}
        </div> -->

        <div class="search-description" *ngIf="searchOpenedFirst">
            <img loading="lazy" class="search-zoom" src="assets/{{appName}}/img/search-zoom.svg">
            <p>{{ configService.localeKeys?.srch_desc_genre_def}}</p>
        </div>
    </div>
</div>
