import { Injectable } from '@angular/core';
import { LoggerService } from '../authentication/logger.service';
import { BaseService } from './base.service';
import { DatalayerService } from './datalayer.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  memoryStorage: any;
  userType: any;

  constructor(private baseService:BaseService,private loggerService: LoggerService,
              private storageService: StorageService,private datalayerService:DatalayerService) { }

  eventFun() {
    let loginEvent = 'False';
    let userId = '';
    let platform = 'Desktop Web';
    let userType = 'Free User';
    let operator_name = '';
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i)){
      platform = 'Mobile Web';
    }
    this.loggerService.memoryStorage.subscribe(data => {
      if (data) {
        this.memoryStorage = data;
        this.userType = this.memoryStorage.user_type;
      }
    });
    if (this.storageService.getLocalStore('u_id')) {
      loginEvent = 'True';
      userId = this.storageService.getLocalStore('u_id');
      if (this.memoryStorage?.user_telco) {
        userType = 'Operator User';
        operator_name = this.memoryStorage.user_telco;
      } else if(this.memoryStorage?.is_voucher_applied == '1'){
        userType = 'Voucher User';
        operator_name = this.memoryStorage.partner_name+'-'+this.memoryStorage.partner_description;
      } else if (this.userType === 1) {
        userType = 'Paid User';
      }
    }
    this.storageService.setLocalStore('user_type', userType);
    this.storageService.setLocalStore('operator_name', operator_name);
    const datalayerData = {
      loginStatus: loginEvent,
      platform,
      userType: userType,
      region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
    };
    this.datalayerService.addDatalayer(datalayerData);
  }
}
