<div [hidden]="!(showIosApp || showAndroidApp)" id="mobile-app">
    <div class="content" id="content">
        <div class="text-center"><img loading="lazy" id="logo-size" [src]="logo"></div>
        <div id="mobile-content">
            <div id="mobile-logo-align" class="basic-text text-center">
                <p>{{ configService.localeKeys?.res_mbl_def_app_def}}</p>
                <div id="logo-head" class="text-center">
                    <p *ngIf="showAndroidApp">
                        <a (click)="deepLinkService.deeplink('isAndroid')">
                            <img class="app-logo" loading="lazy" src="assets/film1/img/android.svg">
                        </a>
                    </p>
                    <p *ngIf="showIosApp">
                        <a (click)="deepLinkService.deeplink('isIphone')">
                            <img loading="lazy" src="assets/film1/img/ios-app.svg">
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
