<div class="h-100">
    <div class="logo-align">
        <img *ngIf='settingsService.metroOnly' class="logo-img" [src]="logo">
        <img *ngIf='!settingsService.metroOnly' [routerLink]="['/home']" class="logo-img cursor-pointer" [src]="logo">
    </div>
    <div class="error-bg-img" [style.background-image]="'url('+homeImage+')'">
        <div class="error-page">
            <div class="error-container card-bg">
                <div class="error-image">
                    <img *ngIf="errorResult?.image" loading="lazy" [src]="errorResult.image" [alt]="errorResult.alt_image">
                </div>
                <div class="error-header">
                    <h2 class="error-text">{{errorResult.title}}</h2>
                </div>
                <div class="error-content">{{errorResult.description}}</div>
                <div *ngIf="!settingsService.metroOnly" class="home-redirection">
                    <a [routerLink]="['/home']" ui-sref="profile.home({region: region})">
                        <button class="take-home-button blue-btn">{{ configService.localeKeys?.err_btn_go_home_def}}</button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
