<div id="pagetitle">
    <h1 class="wdn-text-hidden">{{pageH1Tag}}</h1>
</div>
<app-confirm-card></app-confirm-card>
<div class="bodyParent" [ngClass]="{'show-card': showUpdateCard}" [hidden]="loading">
    <app-header [hidden]="!settingsService.showHeader"></app-header>
    <router-outlet></router-outlet>
    <app-footer [hidden]="!settingsService.showFooter"></app-footer>
</div>
<div class="loader-align loader-center" *ngIf="appName == 'dizi'" [hidden]="!loading">
    <img class="loader-size" src="assets/{{appName}}/img/dizi-loader.svg" alt="loader">
</div>
<div class="loader-align" *ngIf="appName == 'film1' || appName == 'filmbox'" [hidden]="!loading">
    <ng-lottie (domLoaded)="domLoaded()" width="350px" height="100vh" [options]="options"></ng-lottie>
</div>