<button id="signinButton" type="button" class="btn btn-info btn-lg hide" data-toggle="modal" data-target="#myModalSignIn">
{{configService.localeKeys?.open_modal_button}}</button>
<div id="myModalSignIn" class="modal" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <p class="text-center">{{configService.localeKeys?.log_pop_desc_cont_def}}</p>
            </div>
            <div class="text-center align-btn">
                <button class="btn btn-design" type="button" (click)="signIn()">{{ configService.localeKeys?.signin_button}}</button>
                <button class="btn btn-design" [hidden]="back" id="signinclose" type="button" data-dismiss="modal">{{ configService.localeKeys?.log_pop_btn_close_def}}</button>
                <button class="btn btn-design" *ngIf="back==true && close" type="button" (click)="goBack()">{{ configService.localeKeys?.kws_kw_go_back_def}}</button>
            </div>
        </div>
    </div>
</div>