import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApplicationService } from './core/services/application.service';
import { CommonModule } from '@angular/common';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalErrorHandler, JwtInterceptor } from './core/interceptors';

import { CoreModule } from './core/core.module';
import { PublicModule } from './modules/public.module';


import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web/build/player/lottie_svg';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import * as SecureLS from 'secure-ls';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';

export function playerFactory() {
  return player;
}

export function initApp(appService: ApplicationService) {
  return () => appService.initApp();
}

function addRegion(baseUrl: string) {
  const ls = new SecureLS({ isCompression: false });
  let region: string;
  const checkRegion = /region=([a-z.]*)/;
  const locationSearch = location.search;
  const debugRegion = locationSearch.match(checkRegion) ? locationSearch.match(checkRegion)[1] : null;
  try {
    region = debugRegion || ls.get('region');
  } catch (e) {
    region = debugRegion || localStorage.getItem('region');
  }
  return baseUrl + region;
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    CoreModule,
    PublicModule,
    CommonModule,
    LottieModule.forRoot({
      player: playerFactory,
      useCache: true
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [ApplicationService],
      multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => addRegion(s.getBaseHrefFromDOM()),
      deps: [PlatformLocation]
    },
    { 
      provide: OverlayContainer, 
      useClass: FullscreenOverlayContainer 
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

