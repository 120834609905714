import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { BaseService, SettingsService } from 'src/app/core/services';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { DeepLinkService } from 'src/app/core/services/deep-link.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mobile-app',
  templateUrl: './' + environment.htmlAppName + '/mobile-app.component.html',
  // styleUrls: ['./' + environment.appName + '/mobile-app.component.scss']
  styleUrls: ['./' + environment.appName + '/mobile-app.component.scss']

})
export class MobileAppComponent implements OnInit, OnDestroy, AfterViewInit {

  showIosApp: boolean;
  showAndroidApp: boolean;
  showPopup: boolean;
  logo: any;
  androidAppHref: any;
  iosAppHref: any;
  appName = '';

  constructor(private settingsService: SettingsService, private baseService: BaseService,
              public deepLinkService: DeepLinkService, public configService: ConfigurationService) {
    this.logo = this.settingsService.logo;
    this.androidAppHref = this.settingsService.androidAppHref;
    this.iosAppHref = this.settingsService.iosAppHref;
    this.appName = environment.appName;
  }

  ngOnInit(): void {
    this.baseService.checkDevice();
    this.showAndroidApp = this.baseService.showAndroidApp;
    this.showIosApp = this.baseService.showIosApp;
    this.showPopup = true;
    this.closePopUp();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.showIosApp || this.showAndroidApp) {
        this.settingsService.showHeader = false;
        this.settingsService.showFooter = false;
      }
    });
  }

  closePopUp = function() {
    this.showPopup = false;
  };

  ngOnDestroy() {
    this.settingsService.showFooter = true;
    this.settingsService.showHeader = true;
  }

}
