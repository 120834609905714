import { environment } from 'src/environments/environment';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { LoggerService } from 'src/app/core/authentication/logger.service';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/core/services';
import { ConfigurationService } from 'src/app/core/services/configuration.service';

@Component({
  selector: 'app-signin-popup',
  templateUrl: './' + environment.htmlAppName + '/signin-popup.component.html',
  styleUrls: ['./' + environment.appName + '/signin-popup.component.scss']
})
export class SigninPopupComponent implements OnInit, OnDestroy {

  @Input() back: boolean;
  @Input() close: boolean;

  constructor(private location: Location, private loggerService: LoggerService, private router: Router,
              private storageService: StorageService, public configService: ConfigurationService) { }

  ngOnInit(): void {
  }

  signIn() {
    document.getElementById('signinclose').click();
    this.storageService.setLocalStore('contentURL', location.href);
    this.loggerService.updatedMemoryStorage(undefined);
    this.router.navigateByUrl('index/signin');
  }

  goBack() {
    document.getElementById('signinclose').click();
    this.location.back();
  }

  ngOnDestroy(){
    document.getElementById('signinclose').click();
  }

}
