import { environment } from 'src/environments/environment';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseService } from 'src/app/core/services/base.service';
import { SettingsService } from 'src/app/core/services';

@Component({
  selector: 'app-alert-box',
  templateUrl: './' + environment.htmlAppName + '/alert-box.component.html',
  styleUrls: ['./' + environment.appName + '/alert-box.component.scss']
})
export class AlertBoxComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AlertBoxComponent>, @Inject(MAT_DIALOG_DATA) public dialogData,
              private baseService: BaseService,private router: Router, public settingsService: SettingsService) { }

  ngOnInit(): void {
    this.baseService.paymentProcessing.subscribe((value)=>{
      if(!value){
        this.dialogRef.close();
      }
    });
  }

}
