import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { BaseService } from '../services';
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'app-confirm-card',
  templateUrl: './' + environment.htmlAppName + '/confirm-card.component.html',
  styleUrls: ['./confirm-card.component.scss']
})
export class ConfirmCardComponent implements OnInit {
  checkPaymentUpdate: boolean;
  guest: boolean;
  sessionId: any;
  showCard: boolean;
  showUpdateCard = false;
  appName;

  constructor(private baseService:BaseService, public configService: ConfigurationService) {
    this.appName = environment.appName;
   }

  ngOnInit(): void {
    this.baseService.showCardData.subscribe(data =>{
        this.showUpdateCard = !data?.no_past_due;
        this.sessionId = data?.session_id;
    })
  }

}
