import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { ConfirmCardComponent } from './confirm-card/confirm-card.component';
import { LinkComponent } from './footer/link/link.component';
import { FormsModule } from '@angular/forms';
@NgModule({
  declarations: [HeaderComponent, FooterComponent, ConfirmCardComponent, LinkComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule
  ],
  exports: [HeaderComponent, FooterComponent, ConfirmCardComponent],
})
export class CoreModule { }
