<div *ngIf="!guest && showUpdateCard" class="update-card-head navbar-header header_section">
    <div class="info">
        <img src="assets/{{appName}}/img/alert-circle.svg">
        <p>
            {{ configService.localeKeys?.header_confirm_payment_info }}
        </p>
    </div>
    <div>
        <button [routerLink]="['/payment/stripe/update']" [queryParams]="{'session_id':sessionId}" class="update-card">
            {{ configService.localeKeys?.subsc_btn_upd_pmnt_def }}
        </button>
    </div>
</div>
