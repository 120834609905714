import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { catchError, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { StorageService, BaseService } from '../services';
import { LoggerService } from '../authentication/logger.service';
import { sessionAuthURL } from 'src/app/shared/constants/session-url.constants';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { login, paywallHomeURL, contentPersonDataVideo } from 'src/app/shared/constants';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  isRefreshing: any;
  refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private storageService: StorageService, private loggerService: LoggerService,
              private baseService: BaseService, private router:Router) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.storageService.getLocalStore('a_t');
    const refreshToken = this.storageService.getLocalStore('r_t');
    const userId = this.storageService.getLocalStore('u_id');
    const guestId = this.storageService.getLocalStore('g_id');
    const deviceId = this.storageService.getLocalStore('d_id');
    const loggedIn = this.storageService.getLocalStore('logged_in');
    let setParams = {};
    let body = {};
    let headers: HttpHeaders;
    const sessionURL = sessionAuthURL.find(url => request.url.match(url));
    if (request.url.includes(environment.apiUrl) && !sessionURL) {
      if (request.body instanceof FormData) {
        // we are sending a file here
        headers = new HttpHeaders({
          Authorization: 'Bearer ' + accessToken
        });
      }
      if (!request.url.match(/settings/) && !request.url.match(/refresh\/token/) && !request.url.match(/user\/token/) && !request.url.match(paywallHomeURL)) {
        if ((this.storageService.getLocalStore('a_t') || this.loggerService.voucherAccessToken) && !(request.body instanceof FormData)) {
          headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + (this.loggerService.voucherAccessToken || accessToken),
            Accept: '*/*',
          });
        }
        ({setParams, body} = this.addToken(request, accessToken));
      } else if (request.url.match(/refresh\/token/)) {
        if (this.storageService.getLocalStore('r_t')) {
          headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + refreshToken,
            Accept: '*/*',
          });
        }
      }
      if (request.body instanceof FormData) {
        // we are sending a file here
        headers = new HttpHeaders({
          Authorization: 'Bearer ' + accessToken
        });
      }
    } else {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
      });
      body = request.body;
    }
    const cloneReq = request.clone({
      headers, setParams, body
    });
    return next.handle(cloneReq).pipe(
      tap(res => {
        this.loggerService.voucherAccessToken = '';
      },
        error => {
          this.baseService.loaderSource.next(false);
          // 1001 - Token expired
          if (error.error) {
            const errorCodes = [1272, 1211, 1212, 1271, 1231, 1232, 1235, 1236, 4007, 4006];
            const errorCode = error?.error?.error?.code;
            if (errorCode === 1001) { // 101 jwt_token_is_invalid
              this.loggerService.errorLogout();
            } else if (errorCode === 1002 && !request.url.match(refreshToken)) { // 102 jwt_token_is_expired
              return this.handle401Error(request, next);
            } else if (errorCode === 4001) { // 103 jwt_token_is_not_found
              this.loggerService.errorLogout();
            } else if (errorCode === 4005) { // 104 user_device_not_found
              this.loggerService.errorLogout();
            } else if (errorCode === 4003 && !request.url.match(login)) { // 105 user_not_found
              this.loggerService.errorLogout();
            } else if (errorCode === 4004 || errorCode === 4002) { // 106 guest_user_device_not_found
              localStorage.clear();
              this.loggerService.errorLogout();
            } else if (errorCode === 1003) { // 107 refresh_token_expires
              this.loggerService.errorLogout();
            } else if (errorCodes.includes(errorCode) && !(this.router.url.includes('live') && request.url.match(contentPersonDataVideo))) {
               this.baseService.redirectTo('404');
            } else {
              // return res.data;
            }
            if (error.error.status_code === 422) {
              if (error.error.error.code === 1031) {
                const userData = {
                  logged_in: loggedIn,
                  d_id: deviceId,
                  u_id: userId,
                  g_id: guestId
                };
                this.baseService.fetchToken(userData);
              }
              if (error.error.error.code === 1001 && !request.url.match(refreshToken)) {
                return this.handle401Error(request, next);
              }
            }
            if (error.error.status_code === 503 && !(this.router.url.includes('live') && request.url.match(contentPersonDataVideo))) {
              this.router.navigateByUrl('/maintenance');
            }
          }
        })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    let setParams = {};
    let body = {};
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
      Accept: '*/*',
    });
    const region = this.storageService.getLocalStore('region');
    const locale = this.storageService.getLocalStore('locale');
    const countryCode = this.storageService.getLocalStore('country_code');
    if (region && (request.method === 'GET' || request.method === 'DELETE')) {
      setParams = { region };
    } else if (region && (request.method === 'POST' || request.method === 'PUT')) {
      body = Object.assign(request.body, { region });
    }
    if (locale && (request.method === 'GET' || request.method === 'DELETE')) {
      setParams = Object.assign(setParams, { locale });
    } else if (locale && (request.method === 'POST' || request.method === 'PUT')) {
      body = Object.assign(request.body, body, { locale });
    }
    if(countryCode && (request.method === 'GET' || request.method === 'DELETE')){
      setParams = Object.assign(setParams, { country_code: countryCode });
    } else if(countryCode && request.method === 'POST' || request.method === 'DELETE') {
      body = Object.assign(request.body, body, { country_code: countryCode });
    }
    return {body, setParams, headers}
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.loggerService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token['data'].access_token);
          const cloneReq = request.clone(this.addToken(request, token['data'].access_token));
          return next.handle(cloneReq);
        })
        ,catchError(async (error) => this.loggerService.errorLogout())
        ,finalize(async () => this.isRefreshing = false)
      ).subscribe();
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt:string) => {
          const cloneReq = request.clone(this.addToken(request, jwt));
          return next.handle(cloneReq);
        })
      );
    }
  }
  
}
