import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dynamic-svg-color',
  templateUrl: './' + environment.htmlAppName + '/dynamic-svg-color.component.html',
  styleUrls: ['./' + environment.appName + '/dynamic-svg-color.component.scss']
})
export class DynamicSvgColorComponent implements OnInit {
  @Input() color;
  @Input() type;
  appName = '';
  constructor() { }

  ngOnInit(): void {
    this.appName = environment.appName;
  }

}
