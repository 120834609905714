export const sessionAuthURL = [
    '/validate/session',
    '/validate/coupon',
    '/users/plans/',
    '/create/subscription',
    '/complete/subscription',
    '/create/ideal/setup',
    '/create/ideal/pm/setup',
    '/create/payment/method',
    '/complete/payment/method',
    '/create/payment',
    '/complete/payment',
    '/complete/ideal/subscription',
    '/complete/ideal/payment/method',
    '/complete/ideal/payment',
    '/fetch/payment/methods',
    '/check/fingerprint'
];