import { Component, Input, ElementRef, OnChanges} from '@angular/core';

const readMore = 'read-more';
@Component({
    selector: readMore,
    template: `
        <span [innerHTML]="currentText">
        </span>
        <a *ngIf="seo" [style.color]="'var(--bg-font-color)'" [style.cursor] ="'pointer'" [class.hidden]="hideToggle" (click)="toggleView()">
            <span [style.color]="'var(--bg-font-color)'" [style.fontFamily]="'var(--font-bold)'">&nbsp;Read&nbsp;{{isCollapsed? 'more':'less'}}</span>
        </a>
        <a *ngIf="!seo" [style.color]="'var(--bg-font-color)'" [style.cursor] ="'pointer'" [class.hidden]="hideToggle" (click)="toggleView()">
            <span [style.color]="'var(--button-color)'">[&nbsp;</span>{{isCollapsed? '+':'-'}} <span [style.color]="'var(--button-color)'">]</span>
        </a>
    `
})

export class ReadMoreComponent implements OnChanges {
    @Input() text: string;
    @Input() maxLength = 100;
    @Input() seo: boolean;
    currentText: string;
    hideToggle = true;

    public isCollapsed = true;

    constructor() {
    }

    toggleView() {
        this.isCollapsed = !this.isCollapsed;
        this.determineView();
    }
    determineView() {
        if (!this.text || this.text.length <= this.maxLength) {
            this.currentText = this.text;
            this.isCollapsed = true;
            this.hideToggle = true;
            return;
        }
        this.hideToggle = false;
        if (this.isCollapsed === true) {
            this.currentText = this.text.substring(0, this.maxLength) + '...';
        } else if (this.isCollapsed === false)  {
            this.currentText = this.text;
        }
    }
    ngOnChanges() {
        this.determineView();
    }
}
