import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class MobileGuard implements CanActivate, CanActivateChild {

  constructor( private router: Router, private storageService: StorageService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivateHelper(next);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivateHelper(next);
  }

  canActivateHelper(next?, state?) {
    const nextPageUrl = next._routerState.url;
    if(window.innerWidth <= 768 && Boolean(this.storageService.getLocalStore('u_id')) && nextPageUrl !== '/account/m/home') {
      this.router.navigateByUrl('account/m/home');
      return false;
    }
    else if(window.innerWidth <= 768 && !Boolean(this.storageService.getLocalStore('u_id'))) {
      this.router.navigateByUrl('index/signin');
      return false;
    }
    return true;
  }
}
