import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor(private storageService: StorageService) { }

  setVersion(versionData) {
    if (versionData && versionData.version) {
      const version = versionData.version;
      if (this.storageService.getLocalStore('version')) {
        if (this.storageService.getLocalStore('version') !== version) {
          this.storageService.removeAll();
          this.storageService.setLocalStore('version', version);
          window.location.reload();
          return;
        }
      } else {
        this.storageService.removeAll();
        this.storageService.setLocalStore('version', version);
        window.location.reload();
      }
    } else {
      this.storageService.setLocalStore('version', '1.0.0');
    }
  }

}
