import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { LoadService } from './load.service';

@Injectable({
  providedIn: 'root'
})
export class TokenResolverService implements Resolve<any> {

  constructor(private loadService: LoadService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {

    return this.loadService.getToken().then(data => {
      if (data) {
        return data;
      } else {
        return;
      }
    });
  }
}
