import { environment } from 'src/environments/environment';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/core/services';
import { ConfigurationService } from 'src/app/core/services/configuration.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './' + environment.htmlAppName + '/error-page.component.html',
  styleUrls: ['./' + environment.appName + '/error-page.component.scss']
})
export class ErrorPageComponent implements OnInit, OnDestroy {
  logo: string;
  homeImage: any;
  @Input() errorResult;

  constructor(public settingsService: SettingsService,
              public configService: ConfigurationService) { }

  ngOnInit(): void {
    this.settingsService.showHeader = false;
    this.logo = this.settingsService.logo;
    this.homeImage = this.settingsService.homeImage;
  }

  ngOnDestroy() {
    this.settingsService.showHeader = true;
  }

}
