export const VOUCHER_SIGNIN = 'voucher_signin';
export const DISCOUNT_CODE = 'discount_code';
export const DISCOUNT_TYPE = 'discount_type';
export const SOCIAL_ROUTE = 'social_route';
export const TV_ROUTE_FLAG = 'tv_route_flag';
export const L_CATEGORY_META_DATA = 'cat_meta_data';
export const L_SOCIAL_KEY = 'social_key';
export const SOCIAL_APP_TV_FLAG = 'socail_app_tv_flag';
export const AUTH_REDIRECT_ROUTE_FLAG = 'auth_redirect_route_flag';
export const AUTHENTICATE_ROUTE_BACK = 'authenticate_route_back';
export const SMART_TV_REDIRECT_ROUTE_FLAG = 'smarttv_redirect_route_flag';
export const SMART_TV_REDIRECT_ROUTE_BACK = 'smarttv_authenticate_route_back';
export const SOCIAL_SMART_APP_TV_FLAG = 'socail_smart_app_tv_flag';

