<nav class="navbar navbar-default form-navbar" role="navigation">
    <div class="navbar-header form-header">
        <a *ngIf='settingsService.metroOnly' class="navbar-brand">
            <img loading="lazy" [src]="logo" class="hidden-xs">
            <img loading="lazy" [src]="logo" class="visible-xs">
        </a>
        <a *ngIf='!settingsService.metroOnly' class="navbar-brand" (click)="redirectURL()">
            <img loading="lazy" [src]="logo" class="hidden-xs">
            <img loading="lazy" [src]="logo" class="visible-xs">
        </a>
    </div>
    <div class="header-button">
        <a *ngIf="guest" routerLink="/{{routes}}" routerLinkActive="active"> {{ configService.localeKeys[value]}}</a>
        <a *ngIf='!settingsService.metroOnly' routerLink="/{{routes}}" routerLinkActive="active"> {{ configService.localeKeys[value]}}</a>
    </div>
</nav>
